import { Button, TextField } from '@mui/material';
import { useState } from 'react';
import { eventAnalytics } from 'utils/Analytics';
import TabLoading from './TabLoading';
import { TabHintText, TabItem, TabItemContainer } from './TabStyle';

function TabSearchFontName() {
  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  }

  const handleSearch = () => {
    if (value) {
      setLoading(true);

      setTimeout(function () {
        window.open(`${process.env.REACT_APP_API_SEARCH_KEYWORD}?txtsearchword=${encodeURIComponent(value)}`, '_blank');
        setLoading(false);
        setValue('');

      }, 500);

      eventAnalytics('search_font', { 'method': 'keyword' });
    }
  }

  return <>
    {
      loading ?
        <TabLoading message={'폰트를 찾는 중입니다.'} /> :
        <TabItemContainer>
          <TabItem><TabHintText>찾고싶은 폰트를 검색해보세요💫</TabHintText></TabItem>
          <TabItem><TextField value={value} onChange={handleChange} onKeyDown={handleKeyDown} placeholder={'폰트 키워드 검색'} fullWidth /></TabItem>
          <TabItem><Button disabled={value.length === 0} onClick={handleSearch} variant='contained' disableElevation>검색하기</Button></TabItem>
        </TabItemContainer>
    }
  </>
}

export default TabSearchFontName;
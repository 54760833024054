
import { Box, Button, Link, styled as muiStyled, Typography } from '@mui/material';
import backgroundHeader from "assets/images/mobileBackgroundHeader.png";
import { useEffect, useState } from 'react';
import { eventAnalytics } from 'utils/Analytics';
import Colors from 'utils/Colors';

const Wrapper = muiStyled(Box)(({ theme }) => {
  return {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${backgroundHeader})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundPosition: 'bottom',
    color: theme.palette.primary.main,
    textAlign: 'center',
  }
});

const Section = muiStyled(Box)(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
  }
});

const DownloadButton = muiStyled(Button)(({ theme }) => {
  return {
    background: Colors.white,
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: 20,

    '&:hover': {
      background: Colors.white,
      color: theme.palette.primary.main,
    }
  }
});

function MobileMainSection() {
  const [storeLink, setStoreLink] = useState<String>(process.env.REACT_APP_PLAYSTORE);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const isIOS = (/iPad|iPhone|iPod/.test(userAgent)); // (/android/i.test(userAgent)
    if (isIOS) {
      setStoreLink(process.env.REACT_APP_APPSTORE);
    } else {
      setStoreLink(process.env.REACT_APP_PLAYSTORE);
    }

  }, []);

  return <Wrapper id='home'>
    <Section>
      <Typography
        component='h1'
        variant='h2'
        color={Colors.white}
        style={{ fontWeight: 600, fontSize: 30, marginBottom: 30, lineHeight: 1.4 }}
      >
        폰트가 궁금할 땐<br />폰트폰트
      </Typography>


      <DownloadButton variant='contained' disableElevation>
        <Link
          href={storeLink}
          onClick={() => { eventAnalytics('click_storebanner_m'); }}
          target={'_blank'}
          style={{ textDecoration: 'none' }}>앱 다운로드</Link>
      </DownloadButton>
    </Section>
  </Wrapper>;
};

export default MobileMainSection;

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import {
  RecoilRoot
} from 'recoil';
import { initAnalytics } from 'utils/Analytics';
import theme from 'utils/Theme';
import App from './App';
import './assets/styles/style.scss';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

axios.defaults.baseURL = process.env.REACT_APP_API_FONTBOX_URL;
axios.interceptors.request.use(function (config) {
  config.headers['x-api-key'] = process.env.REACT_APP_API_KEY;
  config.headers['language'] = 'auto';
  return config;
});

initAnalytics();

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <HelmetProvider>
          <App />
        </HelmetProvider>
      </ThemeProvider>
    </RecoilRoot>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Container } from '@mui/material';
import { Box, styled as muiStyled } from '@mui/system';
import Copyright from 'components/footer/Copyright';
import Privacy from 'components/footer/Privacy';
import Terms from 'components/footer/Terms';
import styled from 'styled-components';
import Colors from 'utils/Colors';

const FooterContainer = muiStyled(Box)<{ mobile?: boolean }>(({ theme, ...props }) => {
  return {
    backgroundColor: Colors.disabled,
    width: '100%',
    padding: props?.mobile ? 28 : 57,
    color: Colors.black,
    height: props?.mobile ? 70 : 'auto',
    marginTop: props?.mobile ? -70 : 'auto',
    position: props?.mobile ? 'absolute' : 'relative',
    bottom: props?.mobile ? 0 : 'auto',
  }
});

const FooterList = styled.ul<{ mobile?: boolean }>`
  list-style-type: none;
  font-size: ${(props) => props?.mobile ? '12px' : '14px'};
  margin: 0px;
  padding: 0px;
  align-items: center;
  width: 100%;
  display: table;

  & > li {
    float: ${(props) => props?.mobile ? 'right' : 'left'};
    margin-left: ${(props) => props?.mobile ? '0.7rem' : '1rem'};
    text-align: right;
    vertical-align: middle;
    display: table-cell;

    a {
      text-decoration: none;
      color: ${Colors.black};
    }
  }

  & li:first-child {
    float: left;
    text-align: left;
    margin-left: 0rem;
    font-weight: bold;
    margin-right: ${(props) => props?.mobile ? '1rem' : '2rem'};;
  }
`;

function Footer(props?: { mobile?: boolean }) {
  return <FooterContainer mobile={props?.mobile ? true : false}>
    <Container maxWidth='lg'>
      <FooterList mobile={props?.mobile}>
        <li><Copyright /></li>
        <li><Box><Privacy mobile={props?.mobile} /></Box></li>
        <li><Box><Terms mobile={props?.mobile} /></Box></li>
      </FooterList>
    </Container>
  </FooterContainer>;
};

export default Footer;

import CloseIcon from '@mui/icons-material/Close';
import { Link } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import { TransitionProps } from '@mui/material/transitions';
import Typography from '@mui/material/Typography';
import { forwardRef, useEffect, useState } from 'react';
import styled from 'styled-components';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PrivacyFrame = styled.iframe<{ mobile?: boolean }>`
  width: 100%;
  height: ${(props) => props?.mobile ? '100%' : '700px'};
  border: none;
`;

function Terms(props?: { mobile?: boolean, hash?: boolean }) {
  const HASH_KEY = '#terms';
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (props?.hash === true) {
      const onHashChange = () => setOpen(window.location.hash === HASH_KEY);
      window.addEventListener('hashchange', onHashChange);
      return () => window.removeEventListener('hashchange', onHashChange);
    }
  }, [props]);

  const handleClickOpen = () => {
    if (props?.hash === true) {
      window.location.hash = HASH_KEY;
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    if (props?.hash === true) {
      window.history.back();
    } else {
      setOpen(false);
    }
  };

  return <>
    <Link onClick={handleClickOpen} style={{ cursor: 'pointer' }}>서비스 이용약관</Link>
    <Dialog
      fullScreen={props?.mobile === true}
      fullWidth={true}
      maxWidth={'md'}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar
        elevation={0}
        sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge='start'
            color='inherit'
            onClick={handleClose}
            aria-label='close'
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
            서비스이용약관
          </Typography>
        </Toolbar>
      </AppBar>
      <PrivacyFrame src={process.env.REACT_APP_TERMS} mobile={props?.mobile} />
    </Dialog>
  </>;
};

export default Terms;

export interface Font {
  image: string;
  image2x?: string;
  image3x?: string;
  name: string;
  url: string;
  comment: string;
  event: string;
};

export const topFonts: Font[] = [
  { 'image': require('assets/images/220915_search01.png'), 'image2x': require('assets/images/220915_search01@2x.png'), 'image3x': require('assets/images/220915_search01@3x.png'), name: 'Sandoll 네모니2', url: 'https://www.sandollcloud.com/font/866.html', comment: '', event: 'nemony2' },
  { 'image': require('assets/images/220915_search02.png'), 'image2x': require('assets/images/220915_search02@2x.png'), 'image3x': require('assets/images/220915_search02@3x.png'), name: 'Sandoll 스터디윗미', url: 'https://www.sandollcloud.com/font/17331.html', comment: '', event: 'studywithme', },
  { 'image': require('assets/images/220915_search03.png'), 'image2x': require('assets/images/220915_search03@2x.png'), 'image3x': require('assets/images/220915_search03@3x.png'), name: 'Sandoll 시티산스', url: 'https://www.sandollcloud.com/font/15560.html', comment: '', event: 'ctsans' },
  { 'image': require('assets/images/220915_search04.png'), 'image2x': require('assets/images/220915_search04@2x.png'), 'image3x': require('assets/images/220915_search04@3x.png'), name: 'Sandoll 고고라운드', url: 'https://www.sandollcloud.com/font/15620.html', comment: '', event: 'gogoround' },
  { 'image': require('assets/images/220915_search05.png'), 'image2x': require('assets/images/220915_search05@2x.png'), 'image3x': require('assets/images/220915_search05@3x.png'), name: 'Sandoll 늦봄', url: 'https://www.sandollcloud.com/font/228.html', comment: '', event: 'lastspring' },
  { 'image': require('assets/images/220915_search06.png'), 'image2x': require('assets/images/220915_search06@2x.png'), 'image3x': require('assets/images/220915_search06@3x.png'), name: 'Sandoll 호요요', url: 'https://www.sandollcloud.com/font/627.html', comment: '', event: 'hoyoyo' },
  { 'image': require('assets/images/220915_search07.png'), 'image2x': require('assets/images/220915_search07@2x.png'), 'image3x': require('assets/images/220915_search07@3x.png'), name: 'Sandoll 크림빵', url: 'https://www.sandollcloud.com/font/231.html', comment: '', event: 'creampang' },
  { 'image': require('assets/images/220915_search08.png'), 'image2x': require('assets/images/220915_search08@2x.png'), 'image3x': require('assets/images/220915_search08@3x.png'), name: 'Sandoll 제비2', url: 'https://www.sandollcloud.com/font/118.html', comment: '', event: 'jebi2' },
  { 'image': require('assets/images/220915_search09.png'), 'image2x': require('assets/images/220915_search09@2x.png'), 'image3x': require('assets/images/220915_search09@3x.png'), name: 'Sandoll 둥굴림2', url: 'https://www.sandollcloud.com/font/961.html', comment: '', event: 'dunggulrim2' },
  { 'image': require('assets/images/220915_search10.png'), 'image2x': require('assets/images/220915_search10@2x.png'), 'image3x': require('assets/images/220915_search10@3x.png'), name: 'Sandoll 라바', url: 'https://www.sandollcloud.com/font/17255.html', comment: '', event: 'lava' },
  { 'image': require('assets/images/220915_search11.png'), 'image2x': require('assets/images/220915_search11@2x.png'), 'image3x': require('assets/images/220915_search11@3x.png'), name: 'Sandoll 칠성조선소', url: 'https://www.sandollcloud.com/font/854.html', comment: '무료', event: 'chilsungboatyard' },
  { 'image': require('assets/images/220915_search12.png'), 'image2x': require('assets/images/220915_search12@2x.png'), 'image3x': require('assets/images/220915_search12@3x.png'), name: 'Sandoll 티비스켓', url: 'https://www.sandollcloud.com/font/272.html', comment: '무료', event: 'tviceket' },
  { 'image': require('assets/images/220915_search13.png'), 'image2x': require('assets/images/220915_search13@2x.png'), 'image3x': require('assets/images/220915_search13@3x.png'), name: 'Sandoll 스웨거', url: 'https://www.sandollcloud.com/font/141.html', comment: '무료', event: 'swagger' },
  { 'image': require('assets/images/220915_search14.png'), 'image2x': require('assets/images/220915_search14@2x.png'), 'image3x': require('assets/images/220915_search14@3x.png'), name: 'Sandoll 국대떡볶이', url: 'https://www.sandollcloud.com/font/218.html', comment: '무료', event: 'kukdetopokki' },
  { 'image': require('assets/images/220915_search15.png'), 'image2x': require('assets/images/220915_search15@2x.png'), 'image3x': require('assets/images/220915_search15@3x.png'), name: 'Sandoll 미생', url: 'https://www.sandollcloud.com/font/225.html', comment: '무료', event: 'miseang' },
];

export const popularFonts: Font[] = [
  { 'image': require('assets/images/220915_free01.png'), 'image2x': require('assets/images/220915_free01@2x.png'), 'image3x': require('assets/images/220915_free01@3x.png'), name: '나눔스퀘어', url: 'https://www.sandollcloud.com/font/229.html', comment: '', event: 'nanumsquareotf' },
  { 'image': require('assets/images/220915_free02.png'), 'image2x': require('assets/images/220915_free02@2x.png'), 'image3x': require('assets/images/220915_free02@3x.png'), name: '프리텐다드', url: 'https://www.sandollcloud.com/font/16951.html', comment: '', event: 'pretendard' },
  { 'image': require('assets/images/220915_free03.png'), 'image2x': require('assets/images/220915_free03@2x.png'), 'image3x': require('assets/images/220915_free03@3x.png'), name: 'G마켓 산스', url: 'https://www.sandollcloud.com/font/15744.html', comment: '', event: 'gmarketsans' },
  { 'image': require('assets/images/220915_free04.png'), 'image2x': require('assets/images/220915_free04@2x.png'), 'image3x': require('assets/images/220915_free04@3x.png'), name: '배달의민족 도현', url: 'https://www.sandollcloud.com/font/219.html', comment: '', event: 'bmdohyeonotf' },
  { 'image': require('assets/images/220915_free05.png'), 'image2x': require('assets/images/220915_free05@2x.png'), 'image3x': require('assets/images/220915_free05@3x.png'), name: '이서윤체', url: 'https://www.sandollcloud.com/font/17229.html', comment: '', event: 'leeseoyun' },
  { 'image': require('assets/images/220915_free06.png'), 'image2x': require('assets/images/220915_free06@2x.png'), 'image3x': require('assets/images/220915_free06@3x.png'), name: '심경하체', url: 'https://www.sandollcloud.com/font/17228.html', comment: '', event: 'simkyungha' },
  { 'image': require('assets/images/220915_free07.png'), 'image2x': require('assets/images/220915_free07@2x.png'), 'image3x': require('assets/images/220915_free07@3x.png'), name: '인천마이스', url: 'https://www.sandollcloud.com/font/17230.html', comment: '', event: 'incheonmiceotf' },
  { 'image': require('assets/images/220915_free08.png'), 'image2x': require('assets/images/220915_free08@2x.png'), 'image3x': require('assets/images/220915_free08@3x.png'), name: '영도체', url: 'https://www.sandollcloud.com/font/17226.html', comment: '', event: 'yeongdootf' },
  { 'image': require('assets/images/220915_free09.png'), 'image2x': require('assets/images/220915_free09@2x.png'), 'image3x': require('assets/images/220915_free09@3x.png'), name: '엘리스 디지털배움체', url: 'https://www.sandollcloud.com/font/16939.html', comment: '', event: 'elicedigitalbaeumotf' },
  { 'image': require('assets/images/220915_free10.png'), 'image2x': require('assets/images/220915_free10@2x.png'), 'image3x': require('assets/images/220915_free10@3x.png'), name: '잘풀리는하루', url: 'https://www.sandollcloud.com/font/16231.html', comment: '', event: 'jalpullineunharuotf' },
  { 'image': require('assets/images/220915_free11.png'), 'image2x': require('assets/images/220915_free11@2x.png'), 'image3x': require('assets/images/220915_free11@3x.png'), name: '잘풀리는오늘', url: 'https://www.sandollcloud.com/font/16230.html', comment: '', event: 'jalpullineunoneulotf' },
  { 'image': require('assets/images/220915_free12.png'), 'image2x': require('assets/images/220915_free12@2x.png'), 'image3x': require('assets/images/220915_free12@3x.png'), name: '순바탕', url: 'https://www.sandollcloud.com/font/16215.html', comment: '', event: 'sunbatang' },
  { 'image': require('assets/images/220915_free13.png'), 'image2x': require('assets/images/220915_free13@2x.png'), 'image3x': require('assets/images/220915_free13@3x.png'), name: 'Sandoll 삼립호빵체', url: 'https://www.sandollcloud.com/font/15539.html', comment: '', event: 'samliphopangche' },
  { 'image': require('assets/images/220915_free14.png'), 'image2x': require('assets/images/220915_free14@2x.png'), 'image3x': require('assets/images/220915_free14@3x.png'), name: '조선100년체', url: 'https://www.sandollcloud.com/font/16239.html', comment: '', event: 'chosuncentennialotf' },
  { 'image': require('assets/images/220915_free15.png'), 'image2x': require('assets/images/220915_free15@2x.png'), 'image3x': require('assets/images/220915_free15@3x.png'), name: '영양군 음식디미방', url: 'https://www.sandollcloud.com/font/16222.html', comment: '', event: 'yydimibangotf' },
];

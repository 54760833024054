import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { styled as muiStyled } from '@mui/system';
import styled from 'styled-components';
import { Font } from 'types/FontRecognitionResult';
import { eventAnalytics } from 'utils/Analytics';
import Colors from 'utils/Colors';

const FontItemBox = muiStyled(Box)(({ theme }) => {
  return {
    width: '100%',
    borderBottom: '1px solid #707070',
    marginTop: 40,
    cursor: 'pointer',
  }
});

const FontItemTitle = muiStyled(Typography)(({ theme }) => {
  return {
    fontSize: 18,
    textDecoration: 'none',
    color: Colors.black,
    fontWeight: 'bold',
  }
});

const FontImage = styled.img`
  width: auto;
  height: 30px;
  margin-top: 25px;
  margin-bottom: 25px;
`;

function FontItem(props: { font: Font }) {
  const handleClick = (font: Font) => {
    let outLink = `${process.env.REACT_APP_API_SEARCH_ID}/${font.font_id}`;
    if (!font.font_id) {
      outLink = `${process.env.REACT_APP_API_SEARCH_ID_NOT_FOUNT}${font.font_name}`;
    }

    window.open(outLink, '_blank');
    eventAnalytics('click_fontlist');
  };

  return (
    <FontItemBox onClick={() => handleClick(props.font)}>
      <FontItemTitle variant='h6'>
        {props.font.font_name}
      </FontItemTitle>
      <FontImage src={props.font.image_url} />
    </FontItemBox>
  )
};

export default FontItem;

import { Link, styled, Tab, Tabs } from '@mui/material';
import requestNewFont from 'assets/images/requestNewFont.png';
import requestNewFont2x from 'assets/images/requestNewFont@2x.png';
import requestNewFont3x from 'assets/images/requestNewFont@3x.png';
import FontList from 'components/fonts/FontList';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { SearchResultState } from 'recoil/atom/SearchResultState';
import Colors from 'utils/Colors';

const FontTabMode = styled(Tab)<{ selected?: boolean }>(({ theme, ...props }) => {
  return {
    borderRadius: 0,
    marginRight: 25,
    marginLeft: 0,
    backgroundColor: Colors.transparent,
    fontWeight: 'normal',
    fontSize: 32,
    minWidth: 0,
    padding: 0,
    color: Colors.black,
    '&.Mui-selected': {
      fontWeight: 'bold',
    },
  }
});

const RequestNewFont = styled(Link)(({ theme }) => {
  return {
    position: 'fixed',
    top: 880,
    left: 'calc(50% + 500px)',
    cursor: 'pointer',
    zIndex: 1,
  }
});

function SearchResult() {
  const resultResult = useRecoilValue(SearchResultState);
  const [tabIndex, setTabIndex] = useState<number>(0);

  const a11yProps = (index: number) => {
    return {
      id: `font-result-tab-${index}`,
      'aria-controls': `font-result-tabpanel-${index}`,
    };
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return <>
    <Tabs
      value={tabIndex}
      onChange={handleChange}
      scrollButtons='auto'
      variant='standard'
      style={{
        marginBottom: 20,
      }}
      TabIndicatorProps={{
        style: {
          backgroundColor: Colors.transparent,
        }
      }}
    >
      <FontTabMode label='전체' selected={true} {...a11yProps(0)} disableRipple={true} />
      <FontTabMode label='무료' {...a11yProps(1)} disableRipple={true} />
      <FontTabMode label='유료' {...a11yProps(2)} disableRipple={true} />
    </Tabs>

    <FontList
      key={resultResult.crop_image_url}
      fonts={resultResult.fonts} free={tabIndex === 0 ? undefined : (tabIndex === 1)} />

    <RequestNewFont href={process.env.REACT_APP_API_REQUEST_FONT} target={'_blank'}>
      <img
        src={requestNewFont}
        srcSet={`${requestNewFont2x} 2x, ${requestNewFont3x} 3x`}
        alt={'검색결과에 찾는 폰트가 없나요?'} />
    </RequestNewFont>
  </>;
};

export default SearchResult;

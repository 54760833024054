import { Link } from '@mui/material';
import appStore from 'assets/images/appStore.png';
import appStore2x from 'assets/images/appStore@2x.png';
import appStore3x from 'assets/images/appStore@3x.png';
import playStore from 'assets/images/playStore.png';
import playStore2x from 'assets/images/playStore@2x.png';
import playStore3x from 'assets/images/playStore@3x.png';
import styled from 'styled-components';
import { eventAnalytics } from 'utils/Analytics';

const StoreButtonContainer = styled.div`
  display: 'flex';
  textAlign: 'center';
`

const StoreButton = styled.img`
  margin: 5px;
`

function StoreButtons() {
  return (
    <StoreButtonContainer>
      <Link
        href={process.env.REACT_APP_PLAYSTORE}
        target={'_blank'}
        onClick={() => eventAnalytics('click_storebanner_web', { 'os': 'android' })}>
        <StoreButton
          src={playStore}
          srcSet={`${playStore2x} 2x, ${playStore3x} 3x`}
          alt={'Get it on Google Play'} />
      </Link>
      <Link
        href={process.env.REACT_APP_APPSTORE} target={'_blank'}
        onClick={() => eventAnalytics('click_storebanner_web', { 'os': 'ios' })}>
        <StoreButton
          src={appStore}
          srcSet={`${appStore2x} 2x, ${appStore3x} 3x`}
          alt={'Get it on AppStore'} /></Link>
    </StoreButtonContainer >
  )
}

export default StoreButtons;
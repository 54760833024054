import { Box, Container } from '@mui/material';
import { styled as muiStyled } from '@mui/system';
import { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import { Font } from 'types/FontRecognitionResult';
import Colors from 'utils/Colors';
import FontItem from './FontItem';

const NoData = muiStyled(Box)(({ theme }) => {
  return {
    color: Colors.black,
    textAlign: 'center',
    fontSize: 18,
    paddingTop: 30,
    paddingBottom: 30,
  }
});

function FontList(props?: { fonts?: Font[], free?: boolean }) {
  const FREE_COMPANY = 'FREE';
  const [fontItem, setFontItem] = useState<Font[]>([]);

  useEffect(() => {
    let item: Array<Font> = new Array<Font>();
    props?.fonts?.forEach((font: Font, index: number) => {
      if (props.free === true) {
        if (font.company !== FREE_COMPANY) {
          return;
        }
      } else if (props.free === false) {
        if (font.company === FREE_COMPANY) {
          return;
        }
      }

      item.push(font);
    });

    setFontItem(item);

  }, [props]);

  return <Container maxWidth='lg' style={{ padding: 0 }}>
    {fontItem.length > 0 ?
      <>
        {fontItem.map((font: Font, index: number) => {
          return <FontItem font={font} key={`${index}:${font.font_id ?? uuid()}`} />;
        })}
      </> : <NoData>검색 결과가 없습니다.</NoData>}
  </Container>
};

export default FontList;

import { Box, Container, Link, styled as muiStyled, Typography } from '@mui/material';
import backgroundHeader from "assets/images/backgroundHeader.png";
import ciWhiteSmall from 'assets/images/ciWhiteSmall.png';
import ciWhiteSmall2x from 'assets/images/ciWhiteSmall@2x.png';
import ciWhiteSmall3x from 'assets/images/ciWhiteSmall@3x.png';
import TabsContainer from 'components/tabs/TabsContainer';
import styled from 'styled-components';
import Colors from 'utils/Colors';
import { useState } from 'react';

const Header = muiStyled(Box)(({ theme }) => {
  return {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${backgroundHeader})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto',
    backgroundPosition: 'center',
    color: theme.palette.primary.main,
  }
});

const HeaderContainer = muiStyled(Container)(({ theme }) => {
  return {
    textAlign: 'center',
    width: 750,
    position: 'relative',
  }
});

const ImageCI = styled.img`
  left: 0px;
  top: 20px;
`;

function HeaderSection(props: {
  onMessage?: (success: boolean, message: string) => void,
}) {
  const [isHovering, setIsHovering] = useState({
    link1: false,
    link2: false,
    link3: false,
  });
  return (
    <Header>
      <div style={{
        display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', height: '48px'}}>
        <Link href={process.env.REACT_APP_URL}>
          <ImageCI
            src={ciWhiteSmall}
            style={{marginTop: '8px', marginLeft: '50px'}}
            srcSet={`${ciWhiteSmall2x} 2x, ${ciWhiteSmall3x} 3x`}
            title={process.env.REACT_APP_TITLE} />
        </Link>
          <div style={{display: 'flex', alignItems: 'center', width: 'auto',
            color: 'white', marginRight: '50px', height: '24px', fontSize: '12px',  }}>
            <div style={{background: 'white', color: '#4173FA',
              paddingRight: '12px',
              paddingLeft: '6px', paddingTop: '3px', paddingBottom: '3px',
              borderTopLeftRadius: '3px',
              borderBottomLeftRadius: '3px', display: 'flex', alignItems: 'center'}}>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.6669 7.65777L10.5094 8.8153C10.4737 8.85775 10.4296 8.89233 10.3799 8.91683C10.3301 8.94133 10.2759 8.95522 10.2205 8.95762C10.1651 8.96002 10.1098 8.95088 10.0581 8.93077C10.0064 8.91067 9.95951 8.88004 9.9203 8.84083C9.8811 8.80163 9.85047 8.7547 9.83036 8.70303C9.81026 8.65135 9.80112 8.59607 9.80352 8.54067C9.80592 8.48528 9.81981 8.43099 9.84431 8.38125C9.86881 8.33151 9.90338 8.2874 9.94583 8.25174L11.1044 7.09314C11.3946 6.79385 11.5542 6.39171 11.5483 5.97489C11.5424 5.55807 11.3715 5.16061 11.073 4.86965C10.782 4.57113 10.3845 4.40018 9.96772 4.39428C9.5509 4.38838 9.14876 4.54801 8.84947 4.83818L7.69087 5.99677C7.61586 6.07179 7.51411 6.11393 7.40803 6.11393C7.30194 6.11393 7.2002 6.07179 7.12518 5.99678C7.05017 5.92176 7.00803 5.82002 7.00803 5.71393C7.00803 5.60785 7.05017 5.5061 7.12519 5.43109L8.28378 4.27249C8.73267 3.83312 9.33749 3.58969 9.9656 3.59559C10.5937 3.60149 11.1938 3.85623 11.6344 4.30396C12.0832 4.74451 12.3388 5.34524 12.3449 5.97413C12.351 6.60302 12.1071 7.2086 11.6669 7.65777Z" fill="#4173FA"/>
                <path d="M4.27694 8.2836L5.43447 7.12607C5.51111 7.06168 5.6091 7.02836 5.7091 7.0327C5.8091 7.03703 5.90385 7.0787 5.97462 7.14948C6.0454 7.22026 6.08707 7.315 6.09141 7.415C6.09574 7.515 6.06242 7.61299 5.99804 7.68963L4.8405 8.84716C4.55034 9.14646 4.3907 9.5486 4.3966 9.96542C4.4025 10.3822 4.57345 10.7797 4.87197 11.0707C5.16293 11.3692 5.5604 11.5401 5.97721 11.546C6.39403 11.5519 6.79617 11.3923 7.09547 11.1021L8.253 9.94459C8.28867 9.90214 8.33277 9.86757 8.38251 9.84307C8.43225 9.81857 8.48654 9.80468 8.54193 9.80228C8.59733 9.79988 8.65262 9.80902 8.70429 9.82912C8.75596 9.84923 8.80289 9.87986 8.8421 9.91906C8.8813 9.95827 8.91193 10.0052 8.93203 10.0569C8.95214 10.1085 8.96128 10.1638 8.95888 10.2192C8.95648 10.2746 8.94259 10.3289 8.91809 10.3786C8.89359 10.4284 8.85902 10.4725 8.81656 10.5082L7.65903 11.6657C7.21017 12.1052 6.6053 12.3488 5.97711 12.3429C5.34891 12.3371 4.74867 12.0824 4.30805 11.6346C3.86026 11.194 3.60551 10.5937 3.59968 9.96552C3.59385 9.33733 3.83741 8.73246 4.27694 8.2836Z" fill="#4173FA"/>
                <path d="M10.2265 6.27961L6.28195 10.2242C6.20731 10.2989 6.10618 10.341 6.00061 10.3414C5.89504 10.3418 5.7936 10.3004 5.71839 10.2263C5.64338 10.1513 5.60123 10.0496 5.60123 9.94348C5.60123 9.83739 5.64338 9.73565 5.71839 9.66064L9.66298 5.71604C9.738 5.64103 9.83974 5.59889 9.94583 5.59889C10.0519 5.59889 10.1537 5.64103 10.2287 5.71604C10.3028 5.79125 10.3441 5.89269 10.3437 5.99826C10.3433 6.10383 10.3012 6.20496 10.2265 6.27961Z" fill="#4173FA"/>
              </svg>
              <span style={{fontSize: '12px'}}>Family Site</span>
            </div>
            <div style={{background: '#568CFF',
              paddingRight: '12px',
              paddingLeft: '12px', paddingTop: '3px', paddingBottom: '3px',
              borderTopRightRadius: '3px', borderBottomRightRadius: '3px'}}>
              <a
                // onMouseOver={() => setIsHovering({ ...isHovering, link1: true })}
                // onMouseOut={() => setIsHovering({ ...isHovering, link1: false })}
                className={isHovering.link1 ? 'family-font' : ''}
                href="https://m.site.naver.com/1dPh1"
                target="_blank"
                rel="noopener noreferrer"
                style={{marginRight: '19px', cursor: 'pointer', color: 'white', textDecoration: 'none', fontSize: '12px'}}>산돌구름</a>
              <a
                // onMouseOver={() => setIsHovering({ ...isHovering, link2: true })}
                // onMouseOut={() => setIsHovering({ ...isHovering, link2: false })}
                className={isHovering.link2 ? 'family-font' : ''}
                href="https://m.site.naver.com/1dPhg"
                target="_blank"
                rel="noopener noreferrer"
                style={{marginRight: '19px', cursor: 'pointer', color: 'white', textDecoration: 'none', fontSize: '12px'}}>웹폰트</a>
              <a
                // onMouseOver={() => setIsHovering({ ...isHovering, link3: true })}
                // onMouseOut={() => setIsHovering({ ...isHovering, link3: false })}
                className={isHovering.link3 ? 'family-font' : ''}
                href="https://m.site.naver.com/1dPhs"
                target="_blank"
                rel="noopener noreferrer"
                style={{cursor: 'pointer', color: 'white', textDecoration: 'none', fontSize: '12px'}}>유토이미지</a>
            </div>
          </div>
        </div>
      <Container maxWidth='lg' sx={{ pt: 3, pb: 3, }}>
        <HeaderContainer maxWidth='md' sx={{ pt: 8, pb: 6, }}>
          <Typography
            component='h1'
            variant='h2'
            color={Colors.white}
            style={{ fontWeight: 600, fontSize: 48 }}
            gutterBottom
          >
            폰트가 궁금할 땐 폰트폰트
          </Typography>

          <TabsContainer onMessage={props.onMessage} />

        </HeaderContainer>

      </Container>
    </Header>
  )
};

export default HeaderSection;

import { Button } from '@mui/material';
import ciBlue from "assets/images/ciBlue.png";
import ciBlue2x from "assets/images/ciBlue@2x.png";
import ciBlue3x from "assets/images/ciBlue@3x.png";
import axios from 'axios';
import Privacy from 'components/footer/Privacy';
import Terms from 'components/footer/Terms';
import ImageResult from 'components/image/ImageResult';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useRecoilState } from 'recoil';
import { UploadImageResultBox } from 'recoil/atom/UploadResultBoxState';
import styled from 'styled-components';
import { UploadResult } from 'types/UploadResult';
import { eventAnalytics } from 'utils/Analytics';
import Colors from 'utils/Colors';
import TabLoading from './TabLoading';
import { TabHintText, TabItem } from './TabStyle';

const TabCIImage = styled.img`
  width: 52px;
  height: 52px;
  object-fit: cover;
  border-radius: 3px;
`
export const TabBottomItem = styled.div`
  padding: 15px 0px;
  width: 100%;
  font-size: 0.8rem;
  color: ${Colors.black};
  border-top: 1px solid #7ea1ff;
  align-self: flex-end;
  height: 70px;
  margin-top: -70px;
  & > a {
    color: ${Colors.primary};
    text-decoration: none;
  }
`;

const TabItemContainer = styled.div<{ paddingTop: number }>`
  padding-top: ${(props) => `${props?.paddingTop ?? 75}px`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 370px;
  padding-right: 75px;
  padding-left: 75px;
  padding-bottom: 75px;
`;

function TabSearchImage(props: {
  onMessage?: (success: boolean, message: string) => void,
}) {
  const [result, setResult] = useRecoilState(UploadImageResultBox);

  const onDrop = useCallback(async (acceptedFiles: any) => {
    setLoading(true);

    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);
    const response = await axios.post<UploadResult>('/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    setTimeout(function () {
      setLoading(false);

      if (response.data.success && response.status === 200) {
        if (response.data.result) {
          setResult(response.data.result);
        }

      } else {
        props.onMessage?.(false, `파일에서 글씨를 찾지 못했습니다. 글씨가 포함된 이미지로 검색해주세요.
        (10MB 이하의 JPG, JPEG, PNG, BMP 파일 지원)`);
      }

      eventAnalytics('search_font', { 'method': 'img' });

    }, 500);
  }, [props, setResult]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {
        loading ?
          <TabLoading message={'이미지에서 폰트를 찾는 중입니다.'} /> : <>
            <>
              <input {...getInputProps()} />
              <TabItemContainer
                paddingTop={(result && result.text_boxes?.length) ? 75 : 5}
                style={{ opacity: isDragActive ? 0.2 : 1, backgroundColor: isDragActive ? Colors.disabled : Colors.white }}>
                {(result && result.text_boxes?.length) ? <>
                  <ImageResult result={result} />
                </> : <div  {...getRootProps()}>
                  <TabItem><TabCIImage src={ciBlue} srcSet={`${ciBlue2x} 2x, ${ciBlue3x} 3x`} /></TabItem>
                  <TabItem><TabHintText>찾고 싶은 폰트가 담긴 이미지를 여기에 드래그하여 놓아 주세요.</TabHintText></TabItem>
                  <TabItem><Button variant='contained' disableElevation>파일 선택하기</Button></TabItem>
                </div>}
              </TabItemContainer>
              {(result && result.text_boxes?.length) ? <></> : <TabBottomItem>이미지를 업로드하시면 폰트폰트의<br /><Privacy /> 및 <Terms />에 동의하는 것으로 간주됩니다.</TabBottomItem>}
            </>
          </>
      }
    </div >
  )
}

export default TabSearchImage;
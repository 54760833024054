import ReactGA from 'react-ga4';

export const initAnalytics = () => {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
};

export const eventAnalytics = (name: string, params?: any) => {
  try {
    // console.log(name, params);
    ReactGA.event(name, params);
  } catch (e: any) { }
};

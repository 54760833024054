
import image from 'assets/images/mobileImageKeywordSearch.png';
import image2x from 'assets/images/mobileImageKeywordSearch@2x.png';
import image3x from 'assets/images/mobileImageKeywordSearch@3x.png';
import Footer from 'components/footer/Footer';
import MobileSectionTemplate from './MobileSectionTemplate';

function MobileImageKeywordSearchSection(props: { colored?: boolean }) {
  return <>
    <MobileSectionTemplate
      id='imageKeywordSearch'
      title={'키워드 검색'}
      subtitle={'폰트명이나 키워드로<br />폰트를 찾아볼 수 있어요'}
      colored={props.colored}
      image={<img src={image} srcSet={`${image2x} 2x, ${image3x} 3x`} alt={'키워드 검색'} />}>
      <Footer mobile={true} />
    </MobileSectionTemplate>
  </>;
};

export default MobileImageKeywordSearchSection;

import { Box, styled as muiStyled, Typography } from '@mui/material';
import Colors from 'utils/Colors';

const Section = muiStyled(Box)(({ theme }) => {
  return {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    textAlign: 'center',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
  }
});

function MobileSectionTemplate(props: {
  id?: string,
  colored?: boolean,
  title?: string,
  subtitle?: string,
  children?: React.ReactNode;
  image?: React.ReactNode;
}) {
  return <Section
    id={props.id}
    style={{ backgroundColor: props.colored ? Colors.sectionBackground : Colors.white }}>
    <Typography
      component='h1'
      variant='h2'
      color={Colors.black}
      style={{ fontWeight: 600, fontSize: 25, marginBottom: 15 }}
    >
      <span dangerouslySetInnerHTML={{ __html: props.title ?? '' }} />
    </Typography>

    <Typography
      component='h1'
      variant='h2'
      color={Colors.black}
      style={{ fontSize: 14, marginBottom: 20 }}
    >
      <span dangerouslySetInnerHTML={{ __html: props.subtitle ?? '' }} />
    </Typography>

    {props.image}
    {props.children}
  </Section>;
};

export default MobileSectionTemplate;

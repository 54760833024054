import styled from 'styled-components';

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  & > div {
    width: 10px;
    height: 10px;
    margin: 3px;
    border-radius: 50%;
    background-color: #d6d6d6;
    opacity: 1;
    animation: bouncing-loader 0.6s infinite alternate;
  }

  & > div:nth-child(2) {
    animation-delay: 0.2s;
  }

  & > div:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes bouncing-loader {
    to {
      transform: translateY(-8px);
    }

    0% {
      background: #4173f7;
    }
    100% {
      background: #d6d6d6;
    }
  }
`

function Loader() {
  return (
    <LoaderContainer className="bouncing-loader">
      <div></div>
      <div></div>
      <div></div>
    </LoaderContainer>
  )
}

export default Loader;
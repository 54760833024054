import { Link, styled } from '@mui/material';
import { Font } from 'assets/data/fonts';
import { eventAnalytics } from 'utils/Analytics';

const FontItemLink = styled(Link)(({ theme }) => {
  return {
    display: 'grid',
    width: '100%',
    '& > img': {
      width: '100%',
    },
  }
});

function FontBanner(props: { font?: Font, eventName: string }) {
  return (
    <FontItemLink
      href={props.font?.url}
      target={'_blank'}
      onClick={() => eventAnalytics(props.eventName, { 'font': props.font?.event })}>
      <img src={props.font?.image} srcSet={`${props.font?.image2x} 2x, ${props.font?.image3x} 3x`}
        alt={props.font?.name}
        title={props.font?.name} />
    </FontItemLink>
  )
};

export default FontBanner;

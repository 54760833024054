import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { LoadingState } from 'recoil/atom/LoadingState';
import { SearchResultState } from 'recoil/atom/SearchResultState';
import styled from 'styled-components';
import { FontRecognitionData, FontRecognitionResult } from 'types/FontRecognitionResult';
import { TextBox, UploadResultBox } from 'types/UploadResult';
import { eventAnalytics } from 'utils/Analytics';
import ImageResultRect from './ImageResultRect';

const ImageBox = styled.div`
  position: relative;
`

const Image = styled.img<{ width: number, height: number }>`
  width: ${(props) => `${props?.width}px`};
  height: ${(props) => `${props?.height}px`};
`

function ImageResult(props: {
  result: UploadResultBox,
  onSelectResult?: (path: string, result: TextBox) => void,
}) {
  const setSearchResultState = useSetRecoilState<FontRecognitionResult>(SearchResultState);
  const setLoading = useSetRecoilState<boolean>(LoadingState);

  const MAX_WIDTH: number = 680;
  const MAX_HEIGHT: number = 350;

  const [size, setSize] = useState<{
    width: number,
    height: number,
    xRatio: number,
    yRatio: number,
  }>({
    width: props.result.width ?? 0,
    height: props.result.height ?? 0,
    xRatio: 1,
    yRatio: 1,
  });

  useEffect(() => {
    let currentHeight = props.result.height;
    let currentWidth = props.result.width;

    if (currentWidth < MAX_WIDTH && currentHeight < MAX_HEIGHT) {
      const ratio = MAX_WIDTH / currentWidth;
      currentWidth = MAX_WIDTH;
      currentHeight = currentHeight * ratio;
    }

    if (currentWidth > MAX_WIDTH) {
      const ratio = MAX_WIDTH / currentWidth;
      currentWidth = MAX_WIDTH;
      currentHeight = currentHeight * ratio;
    }

    if (currentHeight > MAX_HEIGHT) {
      const ratio = MAX_HEIGHT / currentHeight;
      currentWidth = currentWidth * ratio;
      currentHeight = MAX_HEIGHT;
    }

    setSize({
      width: currentWidth,
      height: currentHeight,
      xRatio: currentWidth / props.result.width,
      yRatio: currentHeight / props.result.height,
    });

  }, [props]);

  const handleSelectResult = async (box: TextBox) => {
    setLoading(true);
    const result = await axios.get<FontRecognitionData>('/font_recognition', { params: { s3_path: props.result.s3_path, text_box: box } });
    if (result.data.result) {
      setSearchResultState(result.data.result);

      const fonts = result.data.result?.fonts;
      if (fonts && (fonts?.length ?? 0) > 0) {
        eventAnalytics('search_font', { 'font_list': fonts[0].font_name });
      }
    }

    setLoading(false);
  };

  return <ImageBox>
    <Image
      width={size.width}
      height={size.height}
      src={props.result.image_url} />

    {props.result.text_boxes?.map((box: TextBox) => <ImageResultRect
      onSelectResult={() => handleSelectResult(box)}
      result={box}
      size={size}
      key={`(${box.x1},${box.x2},${box.y1},${box.y2})`} />)}
  </ImageBox>
}

export default ImageResult;
import { Container } from '@mui/material';
import DefaultResult from 'components/result/DefaultResult';
import SearchResult from 'components/result/SearchResult';
import { useRecoilValue } from 'recoil';
import { SearchResultState } from 'recoil/atom/SearchResultState';

function ResultSection() {
  const searchResultState = useRecoilValue(SearchResultState);

  return (<Container sx={{ py: 8 }} maxWidth='lg'>
    {(searchResultState.fonts?.length ?? 0) > 0
      ? <SearchResult />
      : <DefaultResult />}
  </Container>);
};

export default ResultSection;


import image from 'assets/images/mobileImageURLSearch.png';
import image2x from 'assets/images/mobileImageURLSearch@2x.png';
import image3x from 'assets/images/mobileImageURLSearch@3x.png';
import MobileSectionTemplate from './MobileSectionTemplate';

function MobileImageURLSearchSection(props: { colored?: boolean }) {
  return <MobileSectionTemplate
    id='imageURLSearch'
    title={'이미지 주소 검색'}
    subtitle={'이미지 주소를 붙여넣어<br />궁금한 폰트를 찾아볼 수 있어요'}
    colored={props.colored}
    image={<img src={image} srcSet={`${image2x} 2x, ${image3x} 3x`} alt={'이미지 주소 검색'} />} />;
};

export default MobileImageURLSearchSection;

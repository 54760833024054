import { createTheme } from '@mui/material/styles';
import Colors from './Colors';

const theme = createTheme({
  palette: {
    text: {
      primary: '#4173f7',
      secondary: '#FFFFFF',
      disabled: '#000000',
    },
    primary: {
      main: '#4173F7',
    },
  },
  typography: {
    fontFamily: [
      'Apple SD Gothic Neo',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 23,
          paddingLeft: 40,
          paddingRight: 40,
          paddingTop: 7,
          paddingBottom: 7,
        }
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: Colors.black,
        },
        input: {
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 15,
          paddingRight: 15,
        },
      },
    }
  },
});

export default theme;

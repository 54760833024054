import { Button, TextField } from '@mui/material';
import axios from 'axios';
import ImageResult from 'components/image/ImageResult';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { UploadURLResultBox } from 'recoil/atom/UploadResultBoxState';
import { UploadResult } from 'types/UploadResult';
import { eventAnalytics } from 'utils/Analytics';
import TabLoading from './TabLoading';
import { TabHintText, TabItem, TabItemContainer } from './TabStyle';

function TabSearchURL(props: {
  onMessage?: (success: boolean, message: string) => void,
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [result, setResult] = useRecoilState(UploadURLResultBox);
  const [value, setValue] = useState<string>('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  }

  const handleSearch = async () => {
    if (value) {
      setLoading(true);

      try {
        const response = await axios.get<UploadResult>('/upload', { params: { image_url: value } });
        setTimeout(function () {
          setLoading(false);

          if (response.data.success && response.status === 200) {
            setValue('');

            if (response.data.result) {
              setResult(response.data.result);
            }

          } else {
            props.onMessage?.(false, `파일에서 글씨를 찾지 못했습니다. 글씨가 포함된 이미지로 검색해주세요.
            (10MB 이하의 JPG, JPEG, PNG, BMP 파일 지원)`);
          }

          eventAnalytics('search_font', { 'method': 'url' });

        }, 500);
      } catch (e: any) {
        setLoading(false);
        props.onMessage?.(false, e.message);
      }
    }
  }

  return <>
    {
      loading ?
        <TabLoading message={'폰트를 찾는 중입니다.'} /> :
        <TabItemContainer>
          {(result && result.text_boxes?.length) ? <>
            <ImageResult result={result} />
          </> : <>
            <TabItem><TabHintText>이미지 주소(URL)를 입력해 이미지에 포함된 폰트를 찾아보세요💫</TabHintText></TabItem>
            <TabItem><TextField value={value} onChange={handleChange} onKeyDown={handleKeyDown} placeholder={'이미지 주소(URL) 입력'} fullWidth /></TabItem>
            <TabItem><Button disabled={value.length === 0} onClick={handleSearch} variant='contained' disableElevation>검색하기</Button></TabItem>
          </>}
        </TabItemContainer>
    }
  </>
}

export default TabSearchURL;

import { Box } from '@mui/material';
import { styled as muiStyled } from '@mui/system';
import StoreButtons from 'components/StoreButtons';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { UploadImageResultBox, UploadURLResultBox } from 'recoil/atom/UploadResultBoxState';
import styled from 'styled-components';
import Colors from 'utils/Colors';

const Description = muiStyled(Box)(({ theme }) => {
  return {
    color: Colors.white,
    textAlign: 'center',
    fontSize: 18,
  }
});

const Reset = muiStyled(Box)(({ theme }) => {
  return {
    color: Colors.white,
    position: 'absolute',
    right: 0,
    top: 0,
    textDecoration: 'underline',
    cursor: 'pointer',
    fontsize: 14,
  }
});

const TabSearchBottomContainer = styled.div`
  margin: 0px 0px 0px 0px;
  min-height: 90px;
  position: relative;
`

function TabSearchImageBottom() {
  const result = useRecoilValue(UploadImageResultBox);
  const reset = useResetRecoilState(UploadImageResultBox);

  return <>
    {(result && result.text_boxes && result.text_boxes.length > 0) ? <>
      <Description>알고싶은 폰트의 영역을 선택해주세요.</Description>
      <Reset onClick={reset}>이미지 업로드</Reset>
    </> : <StoreButtons />}
  </>
}

function TabSearchURLBottom() {
  const result = useRecoilValue(UploadURLResultBox);
  const reset = useResetRecoilState(UploadURLResultBox);

  return <>
    {(result && result.text_boxes && result.text_boxes.length > 0) ? <>
      <Description>알고싶은 폰트의 영역을 선택해주세요.</Description>
      <Reset onClick={reset}>URL 입력</Reset>
    </> : <StoreButtons />}
  </>
}

function TabSearchBottom(props: { tabIndex: number }) {

  return (
    <TabSearchBottomContainer>
      {(props.tabIndex === 0) ? <TabSearchImageBottom /> : <></>}
      {(props.tabIndex === 1) ? <TabSearchURLBottom /> : <></>}
      {(props.tabIndex !== 0 && props.tabIndex !== 1) ? <StoreButtons /> : <></>}
    </TabSearchBottomContainer >
  )
}

export default TabSearchBottom;

import image from 'assets/images/mobileImageSearch.png';
import image2x from 'assets/images/mobileImageSearch@2x.png';
import image3x from 'assets/images/mobileImageSearch@3x.png';
import MobileSectionTemplate from './MobileSectionTemplate';

function MobileImageSearchSection(props: { colored?: boolean }) {
  return <MobileSectionTemplate
    id='imageSearch'
    title={'이미지 검색'}
    subtitle={'사진 촬영을 해서<br />궁금한 폰트를 찾아볼 수 있어요'}
    colored={props.colored}
    image={<img src={image} srcSet={`${image2x} 2x, ${image3x} 3x`} alt={'이미지 검색'} />} />;
};

export default MobileImageSearchSection;

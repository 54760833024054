import { Grid, styled, Typography } from '@mui/material';
import { Font, popularFonts, topFonts } from 'assets/data/fonts';
import FontBanner from 'components/fonts/FontBanner';
import Colors from 'utils/Colors';

const ListTitle = styled(Typography)(({ theme }) => {
  return {
    fontWeight: 'bold',
    fontSize: 32,
    color: Colors.black,
    marginBottom: '1rem',
  }
});

const GridBordered = styled(Grid)(({ theme }) => {
  return {
    borderBottom: `1px solid ${Colors.black}`,
    borderRight: `1px solid ${Colors.black}`,
  }
});

const GridBorderedWrapper = styled(Grid)(({ theme }) => {
  return {
    borderTop: `1px solid ${Colors.black}`,
    borderLeft: `1px solid ${Colors.black}`,
  }
});

function DefaultResult() {
  return <>
    <ListTitle variant='h1'>많이 검색된 폰트 🏅</ListTitle>
    <GridBorderedWrapper container spacing={0}>
      {topFonts.map((font: Font) => (
        <GridBordered item key={font.name} spacing={0} rowSpacing={0} columnSpacing={0} xs={4} sm={4} md={4}>
          <FontBanner font={font} eventName={'click_banner_hot'} />
        </GridBordered>
      ))}
    </GridBorderedWrapper>

    <ListTitle variant='h1' sx={{ mt: 10 }}>인기 무료 폰트 🔥</ListTitle>
    <GridBorderedWrapper container spacing={0}>
      {popularFonts.map((font: Font) => (
        <GridBordered item key={font.name} spacing={0} rowSpacing={0} columnSpacing={0} xs={4} sm={4} md={4}>
          <FontBanner font={font} eventName={'click_banner_free'} />
        </GridBordered>
      ))}
    </GridBorderedWrapper>
  </>;
};

export default DefaultResult;

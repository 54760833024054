import { atom } from 'recoil';
import { UploadResultBox } from 'types/UploadResult';

export const UploadImageResultBox = atom<UploadResultBox>({
  key: 'UploadImageResultBox',
  default: {
    height: 0,
    width: 0,
    image_url: '',
    s3_path: '',
    text_boxes: [],
  }
});

export const UploadURLResultBox = atom<UploadResultBox>({
  key: 'UploadURLResultBox',
  default: {
    height: 0,
    width: 0,
    image_url: '',
    s3_path: '',
    text_boxes: [],
  }
});

import styled from 'styled-components';
import { TextBox } from 'types/UploadResult';
import Colors from 'utils/Colors';

const Rect = styled.div<{
  textBox: TextBox,
  size: {
    width: number,
    height: number,
    xRatio: number,
    yRatio: number,
  }
}>`
  left: ${(props) => `${props.textBox.x1 * props.size.yRatio}px`};
  top: ${(props) => `${props.textBox.y1 * props.size.xRatio}px`};
  width: ${(props) => `${(props.textBox.x2 - props.textBox.x1) * props.size.xRatio}px`};
  height: ${(props) => `${(props.textBox.y2 - props.textBox.y1) * props.size.yRatio}px`};
  border: 2px solid ${Colors.primary};
  position: absolute;
  cursor: pointer;
`

function ImageResultRect(props: {
  onSelectResult?: (result: TextBox) => void,
  result: TextBox,
  size: {
    width: number,
    height: number,
    xRatio: number,
    yRatio: number,
  }
}) {

  const handleClick = (result: TextBox) => {
    props.onSelectResult?.(result);
  }

  return <Rect
    textBox={props.result}
    size={props.size}
    onClick={() => handleClick(props.result)}
    title={props.result.text}
  ></Rect>
}

export default ImageResultRect;